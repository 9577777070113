import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

if (process.env.NODE_ENV == 'production') {
  Vue.use(VueGtag, {
    config: { id: 'G-N0158E9S1M' }
  });
}

// set language to english
Vue.prototype.lang = 'en';

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
