






















































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  languageList = [
    { value: 'en', text: 'English', path: '' },
    { value: 'ja', text: '日本語', path: 'ja' }
  ];

  // get language setting from global parameter
  get lang() {
    return Vue.prototype.lang;
  }
  // redirect to different language page
  set lang(val: string) {
    if (Vue.prototype.lang !== val) {
      const path = this.languageList.filter(el => el.value == val)[0].path;
      window.location.href = `/${path}`;
    }
  }

  jumpToGithub() {
    return;
    window.open('https://github.com/senayrup/usrcheck', '_blank');
    this.$gtag.event('click', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'button_click',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: 'github'
    });
  }
  jumpToTwitter() {
    window.open('https://twitter.com/senaianes', '_blank');
    this.$gtag.event('click', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'button_click',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: 'twitter'
    });
  }
}
